<!-- start: Paginator -->
<div class="d-flex flex-wrap py-2 mr-3">
    <ng-pagination
            (pageChange)="pageChange($event)"
            [(page)]="paginator.page"
            [boundaryLinks]="true"
            [collectionSize]="paginator.total"
            [maxSize]="4"
            [pageSize]="paginator.pageSize"
            [rotate]="true"
    ></ng-pagination>
</div>
<div class="d-flex align-items-center py-3">
    <!-- begin: loading -->
    <div *ngIf="isLoading" class="d-flex align-items-center">
        <div class="mr-2 text-muted">Loading...</div>
        <div class="spinner spinner-primary mr-10"></div>
    </div>
    <!-- end: loading -->

    <!-- begin: pageSize -->
    <select
            (change)="sizeChange()"
            [(ngModel)]="paginator.pageSize"
            class="form-control form-control-sm font-weight-bold mr-4 border-0 bg-light false"
            style="width: 75px"
    >
        <option *ngFor="let ps of pageSizes" class="btn">
            {{ ps }}
        </option>
    </select
    ><span class="react-bootstrap-table-pagin ation-total"
>&nbsp;Showing rows {{ paginator.page }} to&nbsp;{{
    paginator.pageSize
    }}
    of&nbsp;{{ paginator.total }}</span
>
    <!-- end: pageSize -->
</div>
<!-- end: Paginator -->
