<ng-template #first>
    <i
            aria-hidden="true"
            class="ki ki-bold-double-arrow-back icon-xs"
            i18n="@@ng.pagination.first"
    ></i>
</ng-template>
<ng-template #previous>
    <i
            aria-hidden="true"
            class="ki ki-bold-arrow-back icon-xs"
            i18n="@@ng.pagination.previous"
    ></i>
</ng-template>
<ng-template #next>
    <i
            aria-hidden="true"
            class="ki ki-bold-arrow-next icon-xs"
            i18n="@@ng.pagination.next"
    ></i>
</ng-template>
<ng-template #last>
    <i
            aria-hidden="true"
            class="ki ki-bold-double-arrow-next icon-xs"
            i18n="@@ng.pagination.last"
    ></i>
</ng-template>
<ng-template #ellipsis>...</ng-template>
<ng-template #defaultNumber let-currentPage="currentPage" let-page>
    {{ page }}
    <span *ngIf="page === currentPage" class="sr-only">(current)</span>
</ng-template>
<a
        (click)="selectPage(1); $event.preventDefault()"
        *ngIf="boundaryLinks"
        [attr.aria-disabled]="previousDisabled() ? 'true' : null"
        [attr.tabindex]="previousDisabled() ? '-1' : null"
        [class.disabled]="previousDisabled()"
        aria-label="First"
        class="btn btn-icon btn-sm btn-light btn-hover-primary mr-2 my-1"
        href
        i18n-aria-label="@@ng.pagination.first-aria"
>
    <ng-template
            [ngTemplateOutletContext]="{
      disabled: previousDisabled(),
      currentPage: page
    }"
            [ngTemplateOutlet]="tplFirst?.templateRef || first"
    ></ng-template>
</a>
<a
        (click)="selectPage(page - 1); $event.preventDefault()"
        *ngIf="directionLinks"
        [attr.aria-disabled]="previousDisabled() ? 'true' : null"
        [attr.tabindex]="previousDisabled() ? '-1' : null"
        [class.disabled]="previousDisabled()"
        aria-label="Previous"
        class="btn btn-icon btn-sm btn-light btn-hover-primary mr-2 my-1"
        href
        i18n-aria-label="@@ng.pagination.previous-aria"
>
    <ng-template
            [ngTemplateOutletContext]="{ disabled: previousDisabled() }"
            [ngTemplateOutlet]="tplPrevious?.templateRef || previous"
    ></ng-template>
</a>
<ng-container *ngFor="let pageNumber of pages">
    <a
            *ngIf="isEllipsis(pageNumber)"
            [attr.aria-current]="pageNumber === page ? 'page' : null"
            [class.active]="pageNumber === page"
            [class.disabled]="isEllipsis(pageNumber) || disabled"
            aria-disabled="true"
            class="btn btn-icon btn-sm border-0 btn-light btn-hover-primary mr-2 my-1"
            tabindex="-1"
    >
        <ng-template
                [ngTemplateOutletContext]="{ disabled: true, currentPage: page }"
                [ngTemplateOutlet]="tplEllipsis?.templateRef || ellipsis"
        ></ng-template>
    </a>
    <a
            (click)="selectPage(pageNumber); $event.preventDefault()"
            *ngIf="!isEllipsis(pageNumber)"
            [attr.aria-current]="pageNumber === page ? 'page' : null"
            [attr.aria-disabled]="disabled ? 'true' : null"
            [attr.tabindex]="disabled ? '-1' : null"
            [class.active]="pageNumber === page"
            [class.disabled]="isEllipsis(pageNumber) || disabled"
            class="btn btn-icon btn-sm border-0 btn-light btn-hover-primary mr-2 my-1"
    >
        <ng-template
                [ngTemplateOutletContext]="{
        disabled: disabled,
        $implicit: pageNumber,
        currentPage: page
      }"
                [ngTemplateOutlet]="tplNumber?.templateRef || defaultNumber"
        ></ng-template>
    </a>
</ng-container>
<a
        (click)="selectPage(page + 1); $event.preventDefault()"
        *ngIf="directionLinks"
        [attr.aria-disabled]="nextDisabled() ? 'true' : null"
        [attr.tabindex]="nextDisabled() ? '-1' : null"
        [class.disabled]="nextDisabled()"
        aria-label="Next"
        class="btn btn-icon btn-sm btn-light btn-hover-primary mr-2 my-1"
        i18n-aria-label="@@ng.pagination.next-aria"
>
    <ng-template
            [ngTemplateOutletContext]="{
      disabled: nextDisabled(),
      currentPage: page
    }"
            [ngTemplateOutlet]="tplNext?.templateRef || next"
    ></ng-template>
</a>
<a
        (click)="selectPage(pageCount); $event.preventDefault()"
        *ngIf="boundaryLinks"
        [attr.aria-disabled]="nextDisabled() ? 'true' : null"
        [attr.tabindex]="nextDisabled() ? '-1' : null"
        [class.disabled]="nextDisabled()"
        aria-label="Last"
        class="btn btn-icon btn-sm btn-light btn-hover-primary mr-2 my-1"
        i18n-aria-label="@@ng.pagination.last-aria"
>
    <ng-template
            [ngTemplateOutletContext]="{
      disabled: nextDisabled(),
      currentPage: page
    }"
            [ngTemplateOutlet]="tplLast?.templateRef || last"
    ></ng-template>
</a>
