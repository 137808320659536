// Spain
export const locale = {
    lang: 'es',
    data: {
        TRANSLATOR: {
            SELECT: 'Elige tu idioma',
        },
        MENU: {
            NEW: 'nuevo',
            ACTIONS: 'Comportamiento',
            CREATE_POST: 'Crear nueva publicación',
            PAGES: 'Pages',
            FEATURES: 'Caracteristicas',
            APPS: 'Aplicaciones',
            DASHBOARD: 'Tablero'
        },
        AUTH: {
            GENERAL: {
                OR: 'O',
                SUBMIT_BUTTON: 'Enviar',
                NO_ACCOUNT: 'No tienes una cuenta?',
                SIGNUP_BUTTON: 'Regístrate',
                FORGOT_BUTTON: 'Se te olvidó tu contraseña',
                BACK_BUTTON: 'Espalda',
                PRIVACY: 'Intimidad',
                LEGAL: 'Legal',
                CONTACT: 'Contacto',
            },
            LOGIN: {
                TITLE: 'Crear una cuenta',
                BUTTON: 'Registrarse',
            },
            FORGOT: {
                TITLE: 'Contraseña olvidada?',
                DESC: 'Ingrese su correo electrónico para restablecer su contraseña',
                SUCCESS: 'Your account has been successfully reset.'
            },
            REGISTER: {
                TITLE: 'Sign Up',
                DESC: 'Enter your details to create your account',
                SUCCESS: 'Your account has been successfuly registered.'
            },
            INPUT: {
                EMAIL: 'Email',
                FULLNAME: 'Fullname',
                PASSWORD: 'Password',
                CONFIRM_PASSWORD: 'Confirm Password',
                USERNAME: 'Usuario'
            },
            VALIDATION: {
                INVALID: '{{name}} is not valid',
                REQUIRED: '{{name}} is required',
                MIN_LENGTH: '{{name}} minimum length is {{min}}',
                AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
                NOT_FOUND: 'The requested {{name}} is not found',
                INVALID_LOGIN: 'The login detail is incorrect',
                REQUIRED_FIELD: 'Required field',
                MIN_LENGTH_FIELD: 'Minimum field length:',
                MAX_LENGTH_FIELD: 'Maximum field length:',
                INVALID_FIELD: 'Field is not valid',
            }
        },
        COMMON: {
            PREVIOUS: 'Anterior',
            SEND: 'Enviar',
            NEXT: 'Siguiente',
        },
        IPM: {
            TITLE: 'Censo de Colaboradores',
            DESCRIPTION: 'El presente Censo de Colaboradores cbc tiene como finalidad conocerte más a ti y a tú hogar en diversos aspectos de la vida cotidiana, de manera que nos permita apoyarte y acompañarte en tu caminar dentro de la organización.',
            START: 'Iniciar encuesta',
            INSTRUCTIONS: {
                TITLE: 'Instrucciones',
                SUBTITLE: 'Para las siguientes secciones:',
                LINE_1: 'Cuando se presenten preguntas con opciones de respuesta, por favor seleccione el número de la respuesta correcta, esta puede ser respuesta única o múltiple.',
                LINE_2: 'Cuando se presenten preguntas sin opciones de respuesta, por favor escriba la respuesta correcta.',
            },
        },
    }
};
