<!-- splash screen -->
<div #splashScreen id="splash-screen">
    <img alt="Logo" src="./assets/media/logos/logo-dark.png"/>
    <svg class="splash-spinner" viewBox="0 0 50 50">
        <circle
                class="path"
                cx="25"
                cy="25"
                fill="none"
                r="20"
                stroke-width="5"
        ></circle>
    </svg>
</div>
